































































import Vue from "vue";
import PreFooter from "@/components/PreFooter.vue";
import Testimonial from "@/components/Testimonial.vue";
import testimonials from "@/assets/data/testimonials.json";

export default Vue.extend({
  components: {
    PreFooter,
    Testimonial,
  },
  data() {
    return {
      testimonials,
      index: 0,
      interval: 0,
    };
  },
  metaInfo(): { title: string } {
    return { title: `Download Bkmark` };
  },
  methods: {
    getSafariImageUrl(): string {
      const images = require.context(
        "../assets/images/safari",
        false,
        /\.png$/
      );
      return images(`./${this.index + 1}.png`);
    },
    setIndex(value: number) {
      clearInterval(this.interval);
      this.index = value;
    },
    submit() {
      // @ts-ignore
      gtag("event", "download-safari", {
        event_category: "click",
      });
    },
  },
  mounted() {
    this.interval = setInterval(() => {
      if (this.index === 4) {
        return (this.index = 0);
      }
      this.index += 1;
    }, 3000);
  },
});
