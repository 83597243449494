

























import Vue from "vue";

export default Vue.extend({
  components: {},
  props: {
    testimonial: {
      type: Object,
      required: true,
    },
    light: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {};
  },
});
